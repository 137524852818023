<template>
  <div class="animated fadeIn">
    <QuotaCard2
      class_name="WarehouseQuotaOverview"
      :label_properties="[{ property_key: 'warehouse_name' }, { property_key: 'tangible_name' }]"
      :color_relationship="{ relationship_name: 'ForTangible', property_key: 'color' }"
      :value_properties="[
        { label: 'Limit', property_key: 'limited_quantity_per_day', hidden: true },
        { label: 'Consume', property_key: 'consumed_quantity_per_day', hidden: true },
        { label: 'Remain', property_key: 'remaining_quantity_per_day' },
      ]"
      :default_per_page="1000"
      :index_params="{
        filters: [
          { filter_type: 'property', existence: 'present', property_key: 'warehouse_id', equal: 2 },
          { filter_type: 'property', existence: 'present', property_key: 'tangible_id', equal: 34 },
        ],
      }"
    />
    <QuotaCard2
      class_name="OrderQuotaOverview"
      :label_properties="[
        { property_key: 'warehouse_name' },
        { property_key: 'tangible_name' },
        { property_key: 'lorry_name' },
        { property_key: 'driver_name' },
        { property_key: 'area_name' },
      ]"
      :color_relationship="{ relationship_name: 'ForTangible', property_key: 'color' }"
      :value_properties="[
        { label: 'Limit', property_key: 'limited_quantity_per_day', hidden: true },
        { label: 'Consume', property_key: 'consumed_quantity_per_day', hidden: true },
        { label: 'Remain', property_key: 'remaining_quantity_per_day' },
      ]"
      :default_per_page="1000"
      :index_params="{
        filters: [
          { filter_type: 'property', existence: 'present', property_key: 'from_date', min: '2019-06-01' },
          { filter_type: 'property', existence: 'present', property_key: 'to_date', max: '2019-07-01' },
          { filter_type: 'property', existence: 'present', property_key: 'warehouse_id', equal: 2 },
          { filter_type: 'property', existence: 'present', property_key: 'tangible_id', equal: 34 },
          { filter_type: 'property', existence: 'present', property_key: 'is_valid', equal: true },
        ],
      }"
    />

    <!-- { filter_type: 'property', existence: 'present', property_key: 'warehouse_id', equal: 2 } -->

    <!-- :label_properties="[{ property_key: 'warehouse_name' }, { property_key: 'tangible_name' }, { property_key: 'driver_name' }, { property_key: 'lorry_name' }]" -->
    <!-- :label_relationships="[
        { relationship_name: 'ForWarehouse', property_key: 'name' },
        { relationship_name: 'ForTangible', property_key: 'name' },
      ]" -->
    <!-- { label: 'Limit', property_key: 'limited_quantity_per_day' },
        { label: 'Consume', property_key: 'consumed_quantity_per_day' }, -->
    <!-- { relationship_name: 'FromWarehouseQuota', label: 'WQ' },
        { relationship_name: 'FromDailyRouteGrouping', label: 'DR' }, -->
    <!-- 
    <QuotaCard
      class_name="WarehouseQuota"
      :label_relationships="[{ relationship_name: 'ForWarehouses', property_key: 'name' }]"
      relationship_name="ForTangibles"
      label_property_key="name"
      value_property_key="quantity_per_day"
    />
    <QuotaCard class_name="DriverCapability" relationship_name="ForDrivers" label_property_key="name" value_property_key="addresses_per_route" />
    <QuotaCard class_name="LorryCapacity" relationship_name="ForLorries" label_property_key="name" value_property_key="weight_per_route" />
    <QuotaCard class_name="LorryCapacity" relationship_name="ForLorries" label_property_key="name" value_property_key="volume_per_route" />
    <QuotaCard class_name="ServiceRouteAvailability" relationship_name="ForServiceRoutes" label_property_key="name" value_property_key="is_available" />
    -->
  </div>
</template>

<script>
export default {
  components: {
    QuotaCard: () => import('./QuotaCard'),
    QuotaCard2: () => import('./QuotaCard2'),
  },
  data() {
    return {};
  },
  props: {},
  computed: {},
  watch: {},
  created() {},
  methods: {},
};
</script>
