var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('QuotaCard2',{attrs:{"class_name":"WarehouseQuotaOverview","label_properties":[{ property_key: 'warehouse_name' }, { property_key: 'tangible_name' }],"color_relationship":{ relationship_name: 'ForTangible', property_key: 'color' },"value_properties":[
      { label: 'Limit', property_key: 'limited_quantity_per_day', hidden: true },
      { label: 'Consume', property_key: 'consumed_quantity_per_day', hidden: true },
      { label: 'Remain', property_key: 'remaining_quantity_per_day' },
    ],"default_per_page":1000,"index_params":{
      filters: [
        { filter_type: 'property', existence: 'present', property_key: 'warehouse_id', equal: 2 },
        { filter_type: 'property', existence: 'present', property_key: 'tangible_id', equal: 34 },
      ],
    }}}),_c('QuotaCard2',{attrs:{"class_name":"OrderQuotaOverview","label_properties":[
      { property_key: 'warehouse_name' },
      { property_key: 'tangible_name' },
      { property_key: 'lorry_name' },
      { property_key: 'driver_name' },
      { property_key: 'area_name' },
    ],"color_relationship":{ relationship_name: 'ForTangible', property_key: 'color' },"value_properties":[
      { label: 'Limit', property_key: 'limited_quantity_per_day', hidden: true },
      { label: 'Consume', property_key: 'consumed_quantity_per_day', hidden: true },
      { label: 'Remain', property_key: 'remaining_quantity_per_day' },
    ],"default_per_page":1000,"index_params":{
      filters: [
        { filter_type: 'property', existence: 'present', property_key: 'from_date', min: '2019-06-01' },
        { filter_type: 'property', existence: 'present', property_key: 'to_date', max: '2019-07-01' },
        { filter_type: 'property', existence: 'present', property_key: 'warehouse_id', equal: 2 },
        { filter_type: 'property', existence: 'present', property_key: 'tangible_id', equal: 34 },
        { filter_type: 'property', existence: 'present', property_key: 'is_valid', equal: true },
      ],
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }